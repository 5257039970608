import { useReportStore } from '~/stores/report';

const useLog = () => {
  const reportStore = useReportStore();
  const preferenceStore = usePreferenceStore();

  const save = (id: string, message: string): void => {
    try {
      const company = preferenceStore.preference.company.name || 'Foodinn';
      reportStore.saveLog(`[${company} | ${id}]: ${message}`);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    save
  };
};

export { useLog };
