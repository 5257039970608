import revive_payload_client_YAfHOEIxEw from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_edBWgVHuHv from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QBjQFCnOKx from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ELOVURu2vQ from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass@1.79._sqqoigljfz4zx7kmmftxcvdouy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_KnD1xecjHM from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BQauEaWbI4 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RVCHnqoINe from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fpyzIKBpXb from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VQIxS4wqOh from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_VIHykRRtAw from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_a3iol6lk26qn2tqtity6eb5ara/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_O4gC3Q8Alv from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.5.1_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass_f3wwqeihpetp4vxfi24d7kyjoe/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_bfa8I6NkSn from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.5.1_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass_f3wwqeihpetp4vxfi24d7kyjoe/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_PKLDjOocFl from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sa_apr5bwzxetfoxk6s7sztlrnnjy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_5qvSDFXbLh from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sa_apr5bwzxetfoxk6s7sztlrnnjy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_2cVpgRbVSA from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_rollup@4.22.4_vite@5.4.7_@types+_hwvnwjk2qxfvrjb64ryrwyf2ki/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_i9kb9StW1Z from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_rollup@4.22.4_vite@5.4.7_@types+_hwvnwjk2qxfvrjb64ryrwyf2ki/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_xFOpRWRpAp from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_rollup@4.22.4_vite@5.4.7_@types+_hwvnwjk2qxfvrjb64ryrwyf2ki/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_dlry8Kmpr6 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WFfTfsedwR from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass@1.79.3_terse_qim246drfqtg3vahvcqtexavwa/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_upd6IoYWUA from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass@1.79.3_t_mlqcjqdf6qiupzbnhcxakojczi/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_pvfONsUOwx from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass@1.79.3_t_mlqcjqdf6qiupzbnhcxakojczi/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_uSc6jyEBQ4 from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typesc_tnugeua74loooxogk7smnkrndu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_8CcCirWtnE from "/app/plugins/directives.ts";
import services_eN9fWZLDQ0 from "/app/plugins/services.ts";
export default [
  revive_payload_client_YAfHOEIxEw,
  unhead_edBWgVHuHv,
  router_QBjQFCnOKx,
  _0_siteConfig_ELOVURu2vQ,
  payload_client_KnD1xecjHM,
  navigation_repaint_client_BQauEaWbI4,
  check_outdated_build_client_RVCHnqoINe,
  chunk_reload_client_fpyzIKBpXb,
  plugin_vue3_VQIxS4wqOh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VIHykRRtAw,
  titles_O4gC3Q8Alv,
  defaults_bfa8I6NkSn,
  siteConfig_PKLDjOocFl,
  inferSeoMetaPlugin_5qvSDFXbLh,
  slideovers_2cVpgRbVSA,
  modals_i9kb9StW1Z,
  colors_xFOpRWRpAp,
  plugin_client_dlry8Kmpr6,
  plugin_WFfTfsedwR,
  pwa_icons_upd6IoYWUA,
  pwa_client_pvfONsUOwx,
  plugin_wy0B721ODc,
  plugin_uSc6jyEBQ4,
  directives_8CcCirWtnE,
  services_eN9fWZLDQ0
]